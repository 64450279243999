import React from "react";
import { Heading, GeneralTextWrappper } from "./style";
import { RichText } from "./../../styles/richText";
import PropTypes from "prop-types";

const GeneralText = ({
	header,
	contentInHtml,
	headerSize,
	gridClass,
	sectionName,
}) => {
	const isTwoColumnSectionContent = sectionName === "TwoColumnSection";
	return (
		<GeneralTextWrappper
			className="GeneralText row"
			isTwoColumnSectionContent={isTwoColumnSectionContent}
		>
			<div className={gridClass}>
				<Heading headerSize={headerSize}>{header}</Heading>
				<RichText>
					<div
						className="rich-text-wrapper"
						dangerouslySetInnerHTML={{ __html: contentInHtml }}
					></div>
				</RichText>
			</div>
		</GeneralTextWrappper>
	);
};

GeneralText.propTypes = {
	header: PropTypes.string.isRequired,
	contentInHtml: PropTypes.string.isRequired,
	headerSize: PropTypes.oneOf(["header26", "header22"]),
};

export default GeneralText;
