import styled from "styled-components";
import {
	FONT_SIZE_18,
	FONT_SIZE_22,
	FONT_SIZE_26,
	MOBILE_L_BP,
	FONT_WT_LIGHT,
	TABLET_M_BP,
} from "@website-builder/utilities-index";

export const Heading = styled.h2`
	font-size: ${({ headerSize }) =>
		headerSize?.value && headerSize.value === "header26" //For Backward Compatibility
			? FONT_SIZE_26
			: !headerSize.value
				? headerSize + "px"
				: FONT_SIZE_22};
	margin: 0;
	margin-bottom: 25px;

	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		font-size: ${FONT_SIZE_18};
		line-height: 23px;
	}
`;

export const GeneralTextWrappper = styled.div`
	${({ isTwoColumnSectionContent }) =>
		isTwoColumnSectionContent
			? `
    margin: 80px 0 80px 96px;
    @media only screen and (max-width: ${TABLET_M_BP}px) {
      margin: 40px 0px;
    }
  `
			: null}
	a,
  a:visited,
  a:active,
  a:hover {
		font-weight: ${FONT_WT_LIGHT};
		color: inherit;
	}
`;
